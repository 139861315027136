import domReady from '@roots/sage/client/dom-ready';
import 'lazysizes';
import squareoff from './components/squareoffs.js'
import Alpine from 'alpinejs'
import navigation from './navigation.js'
import history from './history.js'
import slider from './components/slider.js'
import './components/ageGate.js'
import './components/clipboard.js'
import Headroom from "headroom.js";

window.Alpine = Alpine
window.dataLayer = window.dataLayer || []

/**
 * Application entrypoint
 */
domReady(async () => {
  // select your header or whatever element you wish
  const header = document.querySelector(".headroom");
  const headroom = new Headroom(header);
  navigation.init()
  history.init()
  slider.init()
  headroom.init()
  const nextBtns = document.querySelectorAll('.glide__arrow--right')
  const prevBtns = document.querySelectorAll('.glide__arrow--left')

  squareoff()
  
  if (prevBtns) {
    [...prevBtns].forEach((btn) => {
      btn.addEventListener('click', (e) => {
        dataLayer.push({
          'event': 'click',
          'clickClasses': 'prev',
          'value': 'prev'
        });
      })
    })
  }
  
  if (nextBtns) {
    [...nextBtns].forEach((btn) => {
      btn.addEventListener('click', (e) => {
        dataLayer.push({
          'event': 'click',
          'clickClasses': 'next',
          'value': 'next'
        });
      })
    })
  }

});
Alpine.start()

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);