import domReady from '@roots/sage/client/dom-ready';

const pageElements = document.querySelectorAll('.page-element')
const galleryElements = document.querySelectorAll('.gallery-element')
/**
 * update history when intersecting
 * @param {Array} entries Matching entries
 */
const pageElementIntersect = function(entries) {
    entries.map(entry => {
        if(entry.isIntersecting) {
            updateHistory(entry.target.dataset.url)
        }
    })
}

/**
 * update history when intersecting
 * @param {Array} entries Matching entries
 */
const galleryItemElementIntersect = function(entries) {
    entries.map(entry => {
        if(entry.isIntersecting) {
            updateHistory(entry.target.dataset.url)
        }
    })
}

// The element will intersect when 50% is visible
const pageObserver = new IntersectionObserver(
    pageElementIntersect, {
        root: null,
        rootMargin: '-25% 0px'
    }
)

// The element will intersect when 50% is visible
const galleryObserver = new IntersectionObserver(
    galleryItemElementIntersect, {
        root: null,
        rootMargin: '-25% 0px'
    }
)

const getCurrentUrl = function () {
    const search = location.search
    // Remove query string and trailing slash
    const currentUrl = location.href.replace(search, '').replace(/\/$/, '')
    return currentUrl
}

/**
 * Update the history when url is different
 * @param {Node} el Intersecting Node Element
 */
const updateHistory = function(url) {
    const currentUrl = getCurrentUrl()

    // Only update if url if different
    if(currentUrl !== url) {
        history.replaceState(null, "", url)
    }
}

export default {
    init: function () {
        setTimeout(() => {
            pageElements.forEach(pageElement => this.addObserver(pageElement, 'page'))
            galleryElements.forEach(galleryElement => this.addObserver(galleryElement, 'galleryItem'))
        }, 100)
    },
    addObserver: function(el, observer='page') {
        switch (observer) {
            case 'galleryItem':
                galleryObserver.observe(el)
                break
            case 'page':
                // deliberate blank
            default:
                pageObserver.observe(el)
        }
    }
}
