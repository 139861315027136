import Alpine from 'alpinejs'

Alpine.data('clipboard', () => ({
   copied: false,
   timeout: null,

   /**
    * Copy the post permalink to clipboard.
    *
    */
   copyLink(url) {
      navigator.clipboard.writeText(url)
      this.copied = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
         this.copied = false
      }, 3000)
   }
}))