import domReady from '@roots/sage/client/dom-ready';
import Glide from '@glidejs/glide';

const initSlider = function (selector) {
   new Glide(selector, {
      perView: 3,
      gap: 32,
      bound: true,
      breakpoints: {
         1200: {
            perView: 2.5
         },
         600: {
            perView: 1.5,
            dragThreshold: false
         }
      }
   }).mount()
}

const init  = function () {
   const homeGlide = document.querySelectorAll('.glide')
   const articlesGlides = document.querySelectorAll('.glide-related-articles')
   const galleriesGlide = document.querySelector('.glide-related-galleries')
 
   if (homeGlide) {
      [...homeGlide].forEach((glide) => {
         initSlider(glide)
      })
   }
   if (articlesGlides.length > 0) {
      [...articlesGlides].forEach((glide) => {
         initSlider(glide)
      })
   }
   if (galleriesGlide) {
      initSlider('.glide-related-galleries')
   }
}

export default {
   init
}
