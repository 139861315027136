export default function(scriptUrl, callback) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = scriptUrl
    
    if(callback) {
        script.onload = callback
    }

    document.head.appendChild(script);
}