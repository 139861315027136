import domReady from '@roots/sage/client/dom-ready';

export default () => {
    console.log('squareoffs loaded before DOMReady')
    domReady(async () => {
        console.log('squareoffs loaded')
        let wrapper = null
        const lgScreen = window.matchMedia('(min-width: 768px)')
        const scriptTag = document.createElement('script')
        const iframeTag = document.createElement('iframe')
        
        scriptTag.type = 'text/javascript'
        scriptTag.src = 'https://squareoffs.com/assets/embed.js'
    
        iframeTag.id = 'embed_square_off_5016'
        iframeTag.src = 'https://squareoffs.com/embeds/5016?feed_size=small'
        iframeTag.setAttribute('class', 'squareoffs-group-small')
        iframeTag.setAttribute('class', 'squareoffs-group-small')
        iframeTag.setAttribute('class', 'squareoffs-group-small')
        iframeTag.setAttribute('class', 'squareoffs-group-small')
        iframeTag.setAttribute('name', 'embed_square_off_5016')
        iframeTag.setAttribute('scrolling', 'no')
        iframeTag.setAttribute('frameborder', '0')
        iframeTag.setAttribute('width', '300')
        iframeTag.setAttribute('height', '426.75')
        iframeTag.setAttribute('referrerpolicy', 'no-referrer-when-downgrade')
    
        if (lgScreen.matches) {
            wrapper = document.querySelector('.square-offs-desktop')
        } else {
            wrapper = document.querySelector('.square-offs-mobile')
        }
        console.log("wrapper", wrapper)
        if (wrapper) {
            wrapper.appendChild(scriptTag)
            wrapper.appendChild(iframeTag)
        }
    })
}
