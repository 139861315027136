import history from "./history.js"
import {nextTick, addScript} from './utils/index.js'
import slider from './components/slider.js'

const pageBtn = document.querySelector("#page-btn");
const newPageLimite = 4
let isLoading = false
let nextUrl = false
let newPageCounter = 1

const loadNewPage = function(entries) {
    entries.map((entry) => {
        // If viewable and not alreay loading...
        if(entry.isIntersecting && isLoading == false && newPageCounter < newPageLimite) {
            isLoading = true
            newPageCounter ++
            getContent()
        }
    })
}

/**
 * Increase page number on page button href
 */
const updateLink = function() {
    console.log("NextUrl", nextUrl)
    if (nextUrl) {
        pageBtn.href = nextUrl
    } else {
        const pattern = /\/page\/([\d]*)\/?/g
        const matches = pattern.exec(pageBtn.href)
        
        if(matches) {
            const nextPage = parseInt(matches[1]) + 1
            console.log("nextpage:", nextPage)
            pageBtn.href = pageBtn.href.replace(matches[0],`/page/${nextPage}`)
        } else {
            pageBtn.href = pageBtn.href = `${pageBtn.href}page/2`
        }
    }
}

/**
 * Get new content based on page button href
 * @returns 
 */
const getContent = function() {
    if (newPageCounter === 2 && pageBtn.dataset.dynamicHome !== undefined && document.referrer.includes(location.host) === false) {
        pageBtn.href = `https://${location.host}`
    }
    
    // add ajax/ to the original url
    const url = pageBtn.href.replace(`${location.host}/`,`${location.host}/ajax/`)
    console.log('pageBtn', location.host, pageBtn.href, url)
    fetch(url).then((response) => {
        if(response.status === 404) {
            return false
        }
        return response.text()
    }).then((html) => {
        if (html !== false) {
            addContent(html)
            updateLink()
            isLoading = false
        } else {
            resetBtn()
        }
    })
}

/**
 * Manually reset the link of the pagination btn
 * @param {String} url Url to set to the navigation btn
 */
const resetBtn = function(url = '/') {
    pageBtn.href = url
}   

/**
 * Loads widgets from embed 3rd parties like facebook, twitter, insta,...
 */
const loadWidgets = function()
{
    // If twitter has not been loaded yet, load the script
    if(window.twttr == undefined) {
        addScript('//platform.twitter.com/widgets.js', () => {
            console.log('twitter loading callback')
            window.twttr.widgets.load();
        })
    } else {
        window.twttr.widgets.load();
    }
}

/**
 * Add new HTML before the Pagination Button
 * @param {string} html HTML content to add on the page
 */
const addContent = function(html) {
    const wrapper = document.createElement('div')
    wrapper.classList.add('page-element', 'container', 'mx-auto')
    wrapper.dataset.url = pageBtn.href
    wrapper.innerHTML = html
    history.addObserver(wrapper)

    pageBtn.parentNode.parentNode.insertBefore(wrapper, pageBtn.parentNode)
    nextUrl = wrapper.querySelector('div.main').dataset.nextUrl ?? false

    loadWidgets()

    slider.init()

    // Enquque newly added ads to tagman
    nextTick(() => {
        const ads = [...document.querySelectorAll('.oboxads')].filter((a) => {
            return a.className === "oboxads"
        })

        ads.forEach((ad) => {
            (window.OBOXADSQ || []).push({cmd: "addBanner" })
        })
    })
}

export default {
    init: function() {
        // create and handle observer
        if (pageBtn) {
            const observer = new IntersectionObserver(loadNewPage);
            observer.observe(pageBtn)
        }
    }
}
