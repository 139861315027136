import Alpine from 'alpinejs'

Alpine.data('ageGate', (options = {}) => ({

   day: '',
   month: '',
   year: '',
   show: false,
   message: '',
   site: options.site || '',

   init() {
      const optin = sessionStorage.getItem('ageGateOptin')
      if (optin === null) {
         this.showModal()
      }
   },
   
   showModal() {
      this.show = true
   },

   hideModal() {
      this.show = false
   },

   enter() {
      if (this.validateDate() && this.getAge() >= 21) {
         sessionStorage.setItem('ageGateOptin', 'valid')
         this.hideModal()
      } else {
         this.message = "Vous devez avoir l'âge légal de consommer du cannabis au Québec"
      }
   },

   getAge() {
      const date = Date.parse(`${this.year}/${this.month}/${this.day}`)
      const diff = Date.now() - date
      const ageDt = new Date(diff)

      return Math.abs(ageDt.getUTCFullYear() - 1970)
   },

   validateDate() {
      const date = Date.parse(`${this.year}/${this.month}/${this.day}`)
      return !isNaN(date)
   }
}))