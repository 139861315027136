import nextTick from './nextTick.js'
import addScript from './addScript.js'

export default {
  nextTick,
  addScript
}

export {
  nextTick,
  addScript
}
